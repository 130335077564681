import React, { Fragment } from "react";
import "./overlay.css";
type Props = {
  isOpen: boolean;
  children: JSX.Element;
  onClose: () => void;
};

const Overlay = ({ isOpen, children, onClose }: Props) => {
  return (
    <Fragment>
      {isOpen && (
        <div className="overlay">
          <div className="overlay__background" onClick={onClose} />
          <div className="overlay__container">
            <div className="overlay__controls">
              <button
                className="overlay__close"
                type="button"
                onClick={onClose}
              />
            </div>
            {children}
          </div>
        </div>
      )}
    </Fragment>
  );
};
export default Overlay;
