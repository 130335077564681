import {
  Grid,
  GridToolbar,
  GridColumn as Column,
  GridCellProps,
  GridPageChangeEvent,
  GridPagerSettings,
} from "@progress/kendo-react-grid";

import { useState, useRef, useEffect } from "react";
import Permission from "../../models/Permission";
import EditForm from "./Edit";
import dummydata from "./PermissionsDummy.json";
import "./PermissionList.css";

type Props = {};

interface ActionCommandCellProps extends GridCellProps {
  enterEdit: (item: Permission) => void;
}

interface PermissionCellProps extends GridCellProps {
  hasPermission: boolean;
}

interface AppState {
  items: Permission[];
  total: number;
  skip: number;
  pageSize: number;
  pageable: GridPagerSettings;
}
const ActionCommandCell = (props: ActionCommandCellProps) => {
  return (
    <td style={{ display: "flex", flexDirection: "row", gap: 20 }}>
      <button
        className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
        onClick={() => props.enterEdit(props.dataItem)}
      >
        Edit
      </button>
      <button
        className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
        onClick={() => props.enterEdit(props.dataItem)}
      >
        Delete
      </button>
    </td>
  );
};

const ReadPermissionCell = (props: GridCellProps) => {
  return props.dataItem.hasReadAccess ? (
    <td>
      <span className="k-icon k-i-check-outline"></span>
    </td>
  ) : (
    <td>
      <span className="k-icon k-i-close-outline"></span>
    </td>
  );
};

const WritePermissionCell = (props: GridCellProps) => {
  return props.dataItem.hasWriteAccess ? (
    <td>
      <span className="k-icon k-i-check-outline"></span>
    </td>
  ) : (
    <td>
      <span className="k-icon k-i-close-outline"></span>
    </td>
  );
};

const MyReadPermissionCell = (props: GridCellProps) => (
  <ReadPermissionCell {...props} />
);

const MyWritePermissionCell = (props: GridCellProps) => (
  <WritePermissionCell {...props} />
);

const PermissionList = (props: Props) => {
  const [openPopup, setOpenPopup] = useState<boolean>(false);
  const [initialData, setData] = useState<Permission[]>(dummydata);
  const [filteredData, setFilteredData] = useState<Permission[]>(dummydata);
  const [searchText, setSearchText] = useState<string>("");
  const [editItem, setEditItem] = useState<Permission>({
    id: 123123,
    email: "ramakrushna.seera@kochgs.com",
    hasReadAccess: true,
    hasWriteAccess: true,
  });

  const emptyItem: Permission = {
    email: "",
    hasReadAccess: false,
    hasWriteAccess: false,
    id: "",
   
  };
  const searchRef = useRef<HTMLInputElement>(null);
    
  const enterEdit = (item: Permission) => {
    setOpenPopup(true);
    setEditItem(item);
  };

  //Currently handling only Edit
  const handleSubmit = (event) => {
    let newData = initialData.map((item) => {
      if (event.id === item.id) {
        item = { ...event };
      }
      return item;
    });
    setData(newData);
    setOpenPopup(false);
  };

  const handleCancelEdit = () => {
    setOpenPopup(false);
  };

  const createState = (skip: number, take: number) => {
    let pagerSettings: GridPagerSettings = {
      buttonCount: 5,
      info: true,
      type: "numeric",
      pageSizes: true,
      previousNext: true,
    };
    return {
      items: filteredData.slice(skip, skip + take),
      total: filteredData.length,
      skip: skip,
      pageSize: take,
      pageable: pagerSettings,
    };
  };
 
  const [state, setState] = useState<AppState>(createState(0, 10));
  const onPageChange = (event: GridPageChangeEvent) => {
    setState(createState(event.page.skip, event.page.take));
  };

  const updatePagerState = (key: string, value: string | number | boolean) => {
    const newPageableState = Object.assign({}, state.pageable, {
      [key]: value,
    });
    setState(Object.assign({}, state, { pageable: newPageableState }));
  };

  useEffect(() => {
    let timer = setTimeout(() => {
      if (searchText === null || searchText ==="") {
        setData((prev) => {
          return { ...prev, items: initialData, total: initialData.length };
        });
        // setFilteredData(initialData);
        console.log(initialData)
      }
      if (
        searchText !== "" &&
        searchRef.current?.value !== "" &&
        searchText === searchRef.current?.value
      ) {
        let refreshData = dummydata.filter((item) => {
          return (
            item.email.toLowerCase().indexOf(searchText.toLowerCase()) > -1
          );
        });
        setData((prev) => {
          return {
            ...prev,
            items: refreshData,
            total: refreshData.length,
          };
        });
        setFilteredData(refreshData);
        console.log(filteredData)
        
      }
    }, 500);

    return () => clearTimeout(timer);
  }, [searchText, searchRef]);

  const MyEditCommandCell = (props: GridCellProps) => (
    <ActionCommandCell {...props} enterEdit={enterEdit} />
  );
 

  return (
    <>
      <Grid
        data={filteredData}
        onPageChange={onPageChange}
        total={state.total}
        skip={state.skip}
        pageable={state.pageable}
        pageSize={state.pageSize}
      >
        <GridToolbar>
          <div className="permissionFilter">
            <input
              type="text"
              placeholder="Search user..."
              className="searchField"
              value={searchText}
              ref={searchRef}
              onChange={(event) => {
                setSearchText(event.target.value);
              }}
            />

            <button
              title="Add new"
              className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary "
              onClick={() => {
                enterEdit(emptyItem);
              }}
            >
             
              Add new
            </button>
          </div>
        </GridToolbar>
        {/* <Column field="id" title="ID" width="250px" /> */}
        <Column field="email" title="Email ID" orderIndex={1} />
        <Column
          key="writeAccess"
          field="hasWriteAccess"
          title="Write"
          cell={MyWritePermissionCell}
          orderIndex={2}

          //  cell={(props: GridCellProps) => <>HEllo </>}
        />
        <Column
          key="readAccess"
          field="hasReadAccess"
          title="Read"
          orderIndex={3}
          // cell={(props: GridCellProps) => <>HEllodf </>}
          cell={MyReadPermissionCell}
        />
        <Column field="addedOn" title="Last Modified On" orderIndex={4} />

        <Column title="Actions" cell={MyEditCommandCell} orderIndex={5} />
      </Grid>
      {openPopup && (
        <EditForm
          cancelEdit={handleCancelEdit}
          onSubmit={handleSubmit}
          item={editItem}
        ></EditForm>
      )}
    </>
  );
};

export default PermissionList;
