import { Dialog } from "@progress/kendo-react-dialogs";
import Permission from "../../models/Permission";
import { Form, Field, FormElement } from "@progress/kendo-react-form";
import { Input, Checkbox } from "@progress/kendo-react-inputs";
import "./Edit.css";
interface EditFormProps {
  cancelEdit: () => void;
  onSubmit: (event) => void;
  item: Permission;
}
const EditForm = (props: EditFormProps) => {
  return (
    <Dialog
      title={props.item.id === "" ? "Add New Permission" : "Edit Permissions"}
      onClose={props.cancelEdit}
      className={"editpopup"}
    >
      <Form
        onSubmit={props.onSubmit}
        initialValues={props.item}
        render={(formRenderProps) => (
          <FormElement style={{ minWidth: 450, maxWidth: 650 }}>
            <fieldset className={"k-form-fieldset"}>
              <div className="mb-3">
                <Field name={"email"} component={Input} label={"Email"} />
              </div>

              <div className="mb-3 popupInputLable">
                <Field
                  name={"hasReadAccess"}
                  component={Checkbox}
                  label={"Read Access"}
                />
              </div>
              <div className="mb-3 popupInputLable">
                <Field
                  name={"hasWriteAccess"}
                  component={Checkbox}
                  label={"Write Access"}
                />
              </div>
            </fieldset>{" "}
            <div className="k-form-buttons sumbmitButtons">
              <button
                type={"submit"}
                className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
                onClick={props.cancelEdit}
              >
                Cancel
              </button>
              <button
                type={"submit"}
                className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
                disabled={!formRenderProps.allowSubmit}
              >
                Update
              </button>
            </div>
          </FormElement>
        )}
      ></Form>
    </Dialog>
  );
};

export default EditForm;
