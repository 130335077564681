import { useState } from "react";
import PermissionList from "../../Components/Adminstration/PermissionList";
import NavBar from "../../Components/NavBar/NavBar";
import Selector from "../../Components/Selectors/Selector";

type Props = {};

function Adminstration({}: Props) {
  const [bucket, setBucket] = useState<string>("");

  return (
    <>
      <NavBar></NavBar>
      <Selector
        setSelectedBucketinParent={setBucket}
        buttonLabel={"Fetch users"}
      ></Selector>
      <div className="mainContainer">
        <PermissionList></PermissionList>
      </div>
    </>
  );
}

export default Adminstration;
