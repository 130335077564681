import React from "react";
import "./search.css";
function Searching(props) {
  return (
    <section>
       <div className="spinnerContainer">
        <div className="divSpinner"></div>
        <p className="loadingText">Loading..</p>
      </div>
    </section>
  );
}

export default Searching;
