enum FileTypeForFileView {
  CSV = "text/csv",
  DOC = "application/msword",
  DOCX = "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  GIF = "image/gif",
  HTM = "text/htm",
  HTML = "text/html",
  JPG = "image/jpg",
  JPEG = "image/jpeg",
  PDF = "application/pdf",
  PNG = "image/png",
  PPT = "application/vnd.ms-powerpoint",
  PPTX = "application/vnd.openxmlformats-officedocument.presentationml.presentation",
  TIFF = "image/tiff",
  TXT = "text/plain",
  XLS = "application/vnd.ms-excel",
  XLSX = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  BMP = "image/bmp",
  RDY="text/plain"
}

function getFileType(exten: string): string {
  switch (exten) {
    case (exten = "bmp"):
      return FileTypeForFileView.BMP;
      case (exten = "rdy"):
      return FileTypeForFileView.RDY;
    case (exten = "csv"):
      return FileTypeForFileView.CSV;
    case (exten = "doc"):
      return FileTypeForFileView.DOC;
    case (exten = "docx"):
      return FileTypeForFileView.DOCX;
    case (exten = "gif"):
      return FileTypeForFileView.GIF;
    case (exten = "htm"):
      return FileTypeForFileView.HTM;
    case (exten = "html"):
      return FileTypeForFileView.HTML;
    case (exten = "jpg"):
      return FileTypeForFileView.JPG;
    case (exten = "jpeg"):
      return FileTypeForFileView.JPEG;
    case (exten = "pdf"):
      return FileTypeForFileView.PDF;
    case (exten = "png"):
      return FileTypeForFileView.PNG;
    case (exten = "PNG"):
      return FileTypeForFileView.PNG;
    case (exten = "ppt"):
      return FileTypeForFileView.PPT;
    case (exten = "pptx"):
      return FileTypeForFileView.PPTX;
    case (exten = "tiff"):
      return FileTypeForFileView.TIFF;
    case (exten = "txt"):
      return FileTypeForFileView.TXT;
    case (exten = "xls"):
      return FileTypeForFileView.XLS;
    case (exten = "xlsx"):
      return FileTypeForFileView.XLSX;
    default:
      return FileTypeForFileView.TXT;
  }
}
function getFileExtension(filename: string): string {
  var dotindex = filename.indexOf(".");

  return filename.substring(dotindex + 1, filename.length);
}

function getFileMIMEType(filename: string): string {
  var dotindex = filename.indexOf(".");

  let exte = filename.substring(dotindex + 1, filename.length);

  return getFileType(exte.toLowerCase());
}

export { getFileExtension, getFileMIMEType };

//type: "image/jpeg",
//type: "application/msword",
// type: "application/pdf",
// type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
//type: "text/plain",
// type: "text/csv",
