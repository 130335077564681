import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import reportWebVitals from "./reportWebVitals";
import { AuthProviderProps, AuthProvider } from "react-oidc-context";
import { Provider } from 'react-redux';
import { WebStorageStateStore } from "oidc-client-ts";
import store from "../src/Services/Store";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

var hostname = window.location.href;



// if (hostname === "localhost") hostname = `${hostname}:3000`;

const oidcConfig: AuthProviderProps = {
  authority: "https://auth.kochid.com",
  client_id: "1cace4f5-6e7c-4393-8485-3f999a500ffc",
  redirect_uri: hostname,
  userStore: new WebStorageStateStore({ store: localStorage }),
  redirectMethod: "replace",
  onSigninCallback: () => { },
  automaticSilentRenew: true,
  validateSubOnSilentRenew: true,
  scope:
    "entitlements blcName contactInfo employeeInfo emailAddress supervisorNetworkID workCity orgInfo supervisorFirstName preferredName workStreetAddress costCenter openid profile supervisorPersonnelNumber firstName supervisorLastName phone workLocationState domain name costCenterName networkID status lastName displayName workCountry title workLocationZip workLocation personnelidentifier loginAccountInfo workLocationName offline_access blc company department email userPrincipalName supervisorEmailAddress address employmentType supervisorInfo mobile payCompany groups unique-Attribute4 isSupervisor personnelNumber",

};


root.render(
  <BrowserRouter>
    <AuthProvider {...oidcConfig}>
      <Provider store={store}>

        <App />

      </Provider>
    </AuthProvider>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();


//ClientID
//0c722c28-fdd7-4645-b64d-bc4f240bbf86
