import toast from "react-hot-toast";
import axios from "axios";

import { fileUploadPreSignedURL, uploadFileFetch } from "./AcountService";
const baseURL =process.env.REACT_APP_API_BASEURL??"";
const getFile = async (
  fileNameKey: string,
  fileName: string,
  bucketName: string
): Promise<File> => {
  var res: any = null;
  await toast.promise(
    axios({
      method: "GET",
      responseType: "blob",
      url: `${baseURL}GetFileByKey?BucketName=${bucketName}&key=${fileNameKey}`,
      withCredentials: false,
      headers: {
        Accept: "application/octet-stream",
      },
    })
      .then((response) => {
        const resType = response.headers["content-type"];

        const bl = new Blob([response.data], {
          type: resType,
        });
        const fl = new File([bl], fileName, { type: resType });

        res = fl as File;
      })
      .catch((error) => {
        console.log(error);
      }),
    {
      loading: "Please wait we are preparing file to open for you.",
      success: "File is opened.",
      error: "Error while downloading the file.",
    }
  );

  return res;
};

const downloadFile = async (
  fileNameKey: string,
  fileName: string,
  bucketName: string
): Promise<boolean> => {
  var res: boolean = true;
  await toast.promise(
    axios({
      method: "GET",
      responseType: "blob",
      url: `${baseURL}GetFileByKey?BucketName=${bucketName}&key=${fileNameKey}`,
      withCredentials: false,
      headers: {
        Accept: "application/octet-stream",
      },
    })
      .then((response) => {
        const resType = response.headers["content-type"];
        const bl = new Blob([response.data], {
          type: resType,
        });
        const fl = new File([bl], fileName, { type: resType });
        const url = window.URL.createObjectURL(fl);

        const link = document.createElement("a");

        link.href = url; //"data:application/octet-stream;base64," + bl;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
        link.remove();
        res = true;
      })
      .catch((error) => {
        res = false;
        console.log(error);
      }),
    {
      loading: "Please wait we are preparing file to download for you.",
      success: "File is downloaded check your downloads folder.",
      error: "Error while downloading the file.",
    }
  );

  return res;
};

const uploadFile = async (
  fileNameKey: string | null,
  fileName: string,
  bucketName: string,
  file: File
): Promise<boolean> => {
  var res: boolean = true;
  await toast.promise(savingFile(fileNameKey, fileName, bucketName, file), {
    loading: "Uploading the file.",
    success: `File has been uploaded to ${bucketName}.`,
    error: "Error while uploading the file.",
  });

  return res;
};

const getPreSignedURLFile = async (
  fileNameKey: string | null,
  fileName: string,
  bucketName: string
): Promise<string | null> => {
  var res: string | null = null;
  await toast.promise(
    fileUploadPreSignedURL(bucketName, fileName, fileNameKey)
      .then((result) => {
        res = result;
      })
      .catch((err) => {
        res = null;
      }),
    {
      loading: "Uploading the file.",
      success: ``,
      error: "Error while getting  pre signed URL of the file.",
    }
  );

  return res;
};

const uploadFIleWIthSignedURL = async (
  presignedURL: string,
  file: File,
  bucketName: string
): Promise<boolean> => {
  var res: boolean = true;
  await toast.promise(uploadFileFetch(presignedURL, file), {
    loading: "Uploading the file.",
    success: `File has been uploaded to ${bucketName}.`,
    error: "Error while uploading the file.",
  });

  return res;
};

const savingFile = async (
  fileNameKey: string | null,
  fileName: string,
  bucketName: string,
  file: File
): Promise<boolean> => {
  let presignedURL = await getPreSignedURLFile(
    fileNameKey,
    fileName,
    bucketName
  );
  if (presignedURL) {
    let result = await uploadFIleWIthSignedURL(presignedURL, file, bucketName);
    if (result) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
};
export { getFile, downloadFile, uploadFile, savingFile };
