import * as React from "react";
import "./FileManager.css";
import {
  Toolbar,
  Button,
  ButtonGroup,
  SplitButton,
  SplitButtonItemClickEvent,
} from "@progress/kendo-react-buttons";
import {
  Switch,
  InputChangeEvent,
  SwitchChangeEvent,
} from "@progress/kendo-react-inputs";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { Upload, UploadFileInfo } from "@progress/kendo-react-upload";
import { BaseEvent } from "@progress/kendo-react-common";
import {
  toggleViewBtnGroup,
  toggleSortBtnGroup,
} from "../../helpers/helperMethods";
import {
  GridViewBtnGroup,
  UploadAddEvent,
} from "../../models/FileManagerModels";
import { Label } from "@progress/kendo-react-labels";
import useIsMobile from "../../hooks/useMobile";
import { MOBILE_WIDTH } from "../../constants";
import { RootState } from "../../Services/Store";
import { useSelector } from 'react-redux';

export const FileManagerToolbar = (props) => {
  const [dialogVisible, setDialogVisible] = React.useState<boolean>(false);
  const bucketDetails = useSelector((state:RootState) => state.bucketDetails);
 // console.log(typeof(bucketDetails))
  const [viewBtnGroup, setViewBtnGroup] = React.useState<GridViewBtnGroup>({
    gridView: true,
    listView: false,
  });

  const isMobile = useIsMobile(MOBILE_WIDTH);

  const handleSearchChange = (event: InputChangeEvent) => {
    props.onSearchChange(event);
  };

  const handleSwitchChange = (event: SwitchChangeEvent) => {
    props.onSwitchChange(event);
  };

  const handleGridViewChange = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    if (event) {
      const newBtnGroupState = toggleViewBtnGroup(viewBtnGroup, "grid");
      setViewBtnGroup(newBtnGroupState);

      props.onViewChange({
        viewValue: newBtnGroupState,
        event: event,
      });
    }
  };

  const handleAscBtnClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    if (event) {
      const newBtnGroupState = toggleSortBtnGroup(props.sort[0].dir, "asc");
      props.onSortChange({
        direction: "asc",
        sortValue: newBtnGroupState,
        event: event,
      });
    }
  };

  const handleDescSortBtnClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    const newBtnGroupState = toggleSortBtnGroup(props.sort[0].dir, "desc");
    props.onSortChange({
      direction: "desc",
      sortValue: newBtnGroupState,
      event: event,
    });
  };

  const handleListViewChange = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    if (event) {
      const newBtnGroupState = toggleViewBtnGroup(viewBtnGroup, "list");
      setViewBtnGroup(newBtnGroupState);

      props.onViewChange({
        viewValue: newBtnGroupState,
        event: event,
      });
    }
  };

  const handleItemClick = (event: SplitButtonItemClickEvent) => {
    props.onSortChange(event);
  };

  const handleNewFolderClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    props.onNewFolderClick(event);
  };

  const handleDialogVisibility = (
    event: BaseEvent<Dialog> | React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    setDialogVisible(!dialogVisible);
  };

  const handleFileChange = (event: UploadAddEvent) => {
    props.onFileChange({
      files: event.newState,
      event: event,
    });
  };

  const handleUploadClearList = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    props.onClearFileList(event);
  };

  const handleUploadDone = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    setDialogVisible(!dialogVisible);
    props.onUploadComplete(event);
  };
  const requestIntervalRef = React.useRef({});
  const progressRef = React.useRef({});

  //  ((files: UploadFileInfo[], options: {
  //     formData: FormData;
  //     requestOptions: any;
  // }, onProgress: (uid: string, event: ProgressEvent<EventTarget>) => void) => Promise<...>) | undefined

  const onSaveRequest = (
    files: UploadFileInfo[],
    options: { formData: FormData; requestOptions: any },
    onProgress: (uid: string, event: ProgressEvent<EventTarget>) => void
  ): Promise<{ uid: string }> => {
    const uid = files[0].uid;
    progressRef.current[uid] = 0;
    const saveRequestPromise: Promise<{ uid: string }> = new Promise(
      (resolve, reject) => {
        requestIntervalRef.current[uid] = setInterval(() => {
          resolve({ uid: uid });
          // onProgress(uid, ()=> { loaded: progressRef.current[uid], total: 100 });
          // if (progressRef.current[uid] === 100) {
          //   resolve({ uid: uid });

          //   delete progressRef.current[uid];
          //   clearInterval(requestIntervalRef.current[uid]);
          // } else {
          //   reject({ uid: uid });
          //   progressRef.current[uid] = progressRef.current[uid] + 1;
          // }
        }, 40);
      }
    );
    return saveRequestPromise;
  };

  const onRemoveRequest = (files, options) => {
    const uid = files[0].uid;

    // Simulate remove request
    const removeRequestPromise = new Promise((resolve) => {
      setTimeout(() => {
        resolve({ uid: uid });
      }, 300);
    });

    return removeRequestPromise;
  };





  return (
    <Toolbar className="k-filemanager-toolbar">
      {/* <Button onClick={handleNewFolderClick}>New Folder</Button> */}
      {bucketDetails?.access === "w" ? (
      <Button onClick={handleDialogVisibility}>Upload</Button>):null}
      {dialogVisible && (
        <Dialog
          title={"Upload Files"}
          className={"k-filemanager-upload-dialog"}
          onClose={handleDialogVisibility}
          contentStyle={{ width: "530px" }}
        >
          <Upload
            batch={false}
            multiple={false}
            files={props.files}
            withCredentials={false}
            onAdd={handleFileChange}
            onRemove={handleFileChange}
            onProgress={handleFileChange}
            onStatusChange={handleFileChange}
            // save={onSaveRequest}
            //removeUrl={onRemoveRequest}
            saveUrl={onSaveRequest}
          />
          <DialogActionsBar layout={"end"}>
            <Button onClick={handleUploadClearList}> Clear List</Button>
            <Button themeColor={"primary"} onClick={handleUploadDone}>
              Upload
            </Button>
          </DialogActionsBar>
        </Dialog>
      )}
      <ButtonGroup>
        <Button
          togglable={true}
          icon="k-icon k-i-sort-asc-sm"
          selected={props.sort[0].dir === "asc"}
          onClick={handleAscBtnClick}
        />
        <Button
          togglable={true}
          icon="k-icon k-i-sort-desc-sm"
          selected={props.sort[0].dir === "desc"}
          onClick={handleDescSortBtnClick}
        />
      </ButtonGroup>
      <SplitButton
        text={"Sort By"}
        items={props.splitItems}
        onItemClick={handleItemClick}
      ></SplitButton>
      {/* <ButtonGroup>
        <Button
          togglable={true}
          icon="k-icon k-i-grid-layout"
          selected={viewBtnGroup.gridView}
          onClick={handleGridViewChange}
        />
        <Button
          togglable={true}
          icon="k-icon k-i-grid"
          selected={viewBtnGroup.listView}
          onClick={handleListViewChange}
        />
      </ButtonGroup> */}

      {!isMobile && (
        <>
          <div className="k-spacer">&nbsp;</div>
          <div className="k-filemanager-details-toggle">
            <Label>View Details </Label>
            <Switch defaultChecked={true} onChange={handleSwitchChange} />
          </div>
        </>
      )}
    </Toolbar>
  );
};
{
  /* <div className="k-filemanager-search-tool k-textbox k-toolbar-last-visible">
        <Input
          className="k-input"
          placeholder="Search"
          onChange={handleSearchChange}
        />
        <span className="k-input-icon">
          <span className="k-icon k-i-search"></span>
        </span>
      </div> */
}
