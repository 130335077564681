import {useEffect,useState,memo} from "react";
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import { DocumentViewer } from "react-documents";
import Overlay from "../Overlay/Overlay";
import { getFileExtension, getFileMIMEType } from "../../helpers/FileTypes";

import XLViewer from "./XLViewer";
import DocTypeViewer from "./DocTypeViewer"

type Props = {
  urls: File[];
  excelFile?:File;
  isVisable: boolean;
  fileName: string;
  closeOverlay: () => void;
  openOverlay: () => void;
};

const ViewFile = ({ urls, isVisable, fileName,excelFile, closeOverlay }: Props) => {
  
  const [isDocOrXl, setDocOrXL] = useState(false);  

  const [docurl,SetURL] =useState('');
  const [isDocx, setIsDocx] = useState(false); 

  const reader = new FileReader();
  useEffect(()=>{
   var binaryData = [];
    let ftype = getFileExtension(fileName);
    if (ftype.length > 1) {
      const parts = ftype.split(".");
      ftype= parts[parts.length - 1].toLowerCase();
     
    }
   // console.log(ftype);
    let DocOrXl = ftype === "xlsx" || ftype === "xls"|| ftype=="csv" ;
    setDocOrXL(DocOrXl);
    setIsDocx(ftype === "docx");
    const typef = ftype.toUpperCase() =="XLSX"?"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":ftype.toUpperCase() =="DOCX"?"application/vnd.openxmlformats-officedocument.wordprocessingml.document":"application/zip";
     let dourl = window.URL.createObjectURL(
      new Blob(binaryData, { type:typef })
    );
    SetURL(dourl);
    //console.log(DocOrXl)

  },[isVisable,urls,fileName,isDocOrXl,isDocx])


  return isVisable ? (
    <Overlay isOpen={isVisable} onClose={closeOverlay}>
    {isDocOrXl ? (
   <XLViewer excelFile={excelFile} fileName={fileName}></XLViewer>
 ) : isDocx ? ( 
   <DocTypeViewer docxFile={excelFile}/>
 ) : (
        <DocViewer
          pluginRenderers={DocViewerRenderers}
          theme={{
            primary: "#5296d8",
            secondary: "#ffffff",
            tertiary: "#5296d899",
            textPrimary: "#ffffff",
            textSecondary: "#5296d8",
            textTertiary: "#00000099",
            disableThemeScrollbar: false,
          }}
          documents={urls.map((file) => {
            return {
              uri: window.URL.createObjectURL(file),
              fileName: file.name,
              fileType: getFileMIMEType(file.name),
            };
          })}
          config={{}}
          style={{ height: 700 }}
        />
      )}
    </Overlay>
  ) : (
    <></>
  );
};
export default memo(ViewFile);
