import { Group, Bucket } from "../models/types";
import { combineReducers, legacy_createStore as createStore } from "redux";

export interface RootState {
  group: Group;
  bucketDetails: Bucket;
}

let preloadedState, group: Group = { email: "", profiles: [] };
const fetchedStorageItem = localStorage.getItem("oidc.user:https://auth.kochid.com:1cace4f5-6e7c-4393-8485-3f999a500ffc")

const reducer = (state = group, action) => {
  // console.log(state);
  // handle actions
  return state;
};
const initialBucket: Bucket = {
  id: 0,
  bucketName: "",
  prefix: "",
  projectName: "",
  folder: "",
  access: "",
};

const bucketReducer = (state: Bucket = initialBucket,
  action: { type: string; payload: Bucket }
) => {
  switch (action.type) {
    case 'SET_BUCKET_DETAILS':
      return (action.payload);
    default:

      return state;
  }
};

const setBucketDetail = (bucketDetails) => ({
  type: 'SET_BUCKET_DETAILS',
  payload: bucketDetails,
});

if (fetchedStorageItem) {

  preloadedState = {
    todos: JSON.parse(fetchedStorageItem)
  }

  console.log(preloadedState.todos.profile)
  
  let groups: string[] = [];
  if (typeof(preloadedState.todos.profile.groups) === 'string') {
    groups = [preloadedState.todos.profile.groups];
  } else if (Array.isArray(preloadedState.todos.profile.groups)) {
    groups = preloadedState.todos.profile.groups;
  }

  group = {
    email: preloadedState.todos.profile.email,
    profiles: groups.map((item, key) => {
      const split = item.split("_");
      return {
        id: key,
        bucketName: split[0],
        folder: split[1],
        access: split[2],
      };
    }),
  };
}

const rootReducer = combineReducers({
  group: reducer,
  bucketDetails: bucketReducer
});

const store = createStore(rootReducer);
//const storedProfile = store.getState();
store.subscribe(() => {
  console.log('current state', store.getState());
});

export { rootReducer, setBucketDetail };

export default store;

