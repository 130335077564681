import { useCallback, useState, useEffect } from "react";

import SearchPlaceholderIlustration from "../../assets/filesPlaceholder.svg";

import NoFilesFoundIlustration from "../../assets/noFilesFound.svg";

import { Toaster } from "react-hot-toast";

import "./Home.css";

import Explorer from "../../Components/FileExplorer/Explorer";
import NavBar from "../../Components/NavBar/NavBar";
import Selector from "../../Components/Selectors/Selector";
import { DataModel } from "../../models/FileManagerModels";
import { getFiles } from "../../Services/AcountService";
import Searching from "../../animation/Searching";

export default function Home() {
  const [selectedBucket, setSelectedBucket] = useState("");
  const [isfindFilesWithinBucket, setIsfindFilesWithinBucket] = useState(false);

  const [isSearching, setSearching] = useState(false);
  const [stateData, setStateData] = useState<DataModel[]>([]);
  const [isDataChange, setDataChange] = useState<boolean>(false);
 

  const GetFiles = useCallback(async () => {
    setSearching(true);
    await getFiles(selectedBucket)
      .then((files) => {
        if (files.length !== 0) {
          setStateData(files);
        } else {
          setStateData([]);
        }
      })
      .catch(() => {
        setStateData([]);
      });

    setIsfindFilesWithinBucket(true);
    setSearching(false);
  }, [selectedBucket]);

  useEffect(() => {
    if (selectedBucket !== "") {
      GetFiles();
    }
  }, [selectedBucket]);

  const converttostr = (v: boolean) => {
    if (v) return "true";
    return "false";
  };

  const handleFileUploadSuccess = async (uploadedFileInfo) => {
    console.log("File uploaded successfully:", uploadedFileInfo);
  
    if (uploadedFileInfo) {
      setSearching(true);
  
    
      await new Promise((resolve) => setTimeout(resolve, 3000));
  
      for (let i = 0; i < 4; i++) {
        console.log(i);
        await GetFiles();
      }
  
      setSearching(false);
    }
  };
  
  

  return (
    <>
      <NavBar></NavBar>

      <Toaster position="bottom-left"></Toaster>

      <div className="mainContainer" id="search">
        <Selector
          setSelectedBucketinParent={setSelectedBucket}
          buttonLabel={"Find Files"}
        ></Selector>

        {isSearching ? (
          <div className="mainContentAn">
            <Searching></Searching>
          </div>
        ) : (
          <>
            {isfindFilesWithinBucket ? (
              stateData.length === 0 ? (
                <div className="mainContent setFileSearchImage ">
                  <img
                    id="searchPlaceholder"
                    src={NoFilesFoundIlustration}
                    alt="No Files Found"
                  />
                  {/* <div className="placeholderLabel">
                    No Files/Folder found for this bucket.
                  </div> */}
                  <Explorer
                    key={converttostr(isDataChange)}
                    stateData={stateData}
                    bucketName={selectedBucket}
                    isUploadOnly={true}
                    onFileUploadSuccess={handleFileUploadSuccess} 
                  ></Explorer>
                </div>
              ) : (
                <Explorer
                  key={converttostr(isDataChange)}
                  stateData={stateData}
                  bucketName={selectedBucket}
                  isUploadOnly={false}
                  onFileUploadSuccess={handleFileUploadSuccess} 
                ></Explorer>
              )
            ) : (
              <div className="mainContent setFileSearchImage">
                <img
                  id="searchPlaceholder"
                  src={SearchPlaceholderIlustration}
                  alt="Enter Details"
                />
                <div className="placeholderLabel">
                  Enter details to search files in s3 bucket.
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
}
