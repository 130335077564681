import { useInternationalization } from "@progress/kendo-react-intl";
import {
  convertDateFormat,
  getName,
  formatBytes,
} from "../../helpers/helperMethods";
import { useSelector } from 'react-redux';

import { classNames } from "@progress/kendo-react-common";
import { DataModel } from "../../models/FileManagerModels";
import { Button } from "@progress/kendo-react-buttons";
import { useCallback, useMemo, useState } from "react";
import ViewFile from "../FileViewer/ViewFile";
import { downloadFile, getFile } from "../../Services/FileService";
import { RootState } from "../../Services/Store";


export const FileInformation = (props) => {

  return props.data
    ? typeof props.data === "number"
      ? MultipleSelectionRendering(props.data)
      : FileSelectionRendering(props.data)
    : NoDataRendering();
};

const NoDataRendering = () => {
  return (
    <div className="k-filemanager-preview" style={{ width: "100%", border: 0 }}>
      <div className="k-file-info">
        <span className="k-file-preview">
          <span className="k-file-icon k-icon k-i-none"></span>
        </span>
        <span className="k-file-name k-no-file-selected">No File Selected</span>
      </div>
    </div>
  );
};

const FileSelectionRendering = (item: DataModel) => {

  const [isFileOpen, setIsFileOpen] = useState<boolean>(false);
  const [currentdocument, setDocument] = useState<File[]>([]);
  const bucketDetails = useSelector((state:RootState) => state.bucketDetails);
  const intl = useInternationalization();
  const dateCreated: String = convertDateFormat(
    item.dateCreated ? item.dateCreated : null,
    intl
  );
  // const dateModified: Date|null =item.dateModified ? item.dateModified : null; 
   const dateModified:  String = convertDateFormat(
    item.dateModified ? item.dateModified : null,
    intl
  );
 const toggleFileViewer = () => {
    setIsFileOpen((prevState) => !prevState);
  };

  const closeOverlay = () => {
    setIsFileOpen(false);
    setDocument([]);
  };

  const viewFile = useMemo(() => {
    let contentDisposition = item?.fileKey ?? ""
    //console.log(contentDisposition);
    const filenameMatch = contentDisposition.split("/")
    const filenameN = filenameMatch[filenameMatch.length-1]
    return (
      <ViewFile
        isVisable={isFileOpen}
        closeOverlay={closeOverlay}
        openOverlay={toggleFileViewer}
        fileName={filenameN}
        excelFile={currentdocument[0]}
        urls={currentdocument}
      ></ViewFile>
    );
  }, [isFileOpen, item, currentdocument]);

  const getFileForView = useCallback(async () => {
    try {
      let fileData = await getFile(
        item?.fileKey ?? "",
        getName(item.path) ?? "",
        item?.bucketName ?? ""

      );
    
      setDocument(() => [fileData]);
      toggleFileViewer();
    } catch (error) {
      console.log("Filed to open file due to failed to get file from server");
    }
  }, [item]);

  const handleDownloadClick = useCallback(async () => {
    try {
      await downloadFile(
        item?.fileKey ?? "",
        getName(item.path) ?? "",
        item?.bucketName ?? ""
      );
    
    } catch (error) {
      console.log(
        "Filed to download file due to failed to get file from server"
      );
    }
  }, [item]);






  return (
    <>
      {viewFile}
      <div
        className="k-filemanager-preview"
        style={{ width: "100%", border: 0 }}
      >
        <div className="k-file-info">
          <span className="k-file-preview">
            <span
              className={classNames(
                "k-file-icon k-icon",
                item.icon ? item.icon.iconClass : ""
              )}
            ></span>
          </span>
          <span className="k-file-name k-single-file-selected">
            {item.path ? getName(item.path) : ""}
          </span>
          <dl className="k-file-meta">
            <dt className="k-file-meta-label">Type: </dt>
            <dd className={"k-file-meta-value k-file-type"}>
              {" "}
              {item.icon ? item.icon.type : ""}
            </dd>
            <dd className="k-line-break"></dd>
            <dt className="k-file-meta-label">Size: </dt>
            <dd className="k-file-meta-value k-file-size">
              {" "}
              {item.size ? formatBytes(item.size) : ""}
            </dd>
            {/* <dd className="k-line-break"></dd>
            <dt className="k-file-meta-label">Date Created: </dt>
            <dd className="k-file-meta-value k-file-created">
              {dateCreated ? dateCreated : ""}
            </dd> */}
            <dd className="k-line-break"></dd>
            <dt className="k-file-meta-label">Last Modified Date: </dt>
            <dd className="k-file-meta-value k-file-modified">
              {item.dateModified ? dateModified : ""}
            </dd>
            <dd className="k-line-break">
              {item.icon && item.icon.type !== "Folder" ? (
                <>
                  <div className="dowbloadandView">
              {bucketDetails?.access === "w" ? (
                      <Button themeColor={"primary"} onClick={handleDownloadClick}>
                        Download
                      </Button>
                    ) : null} 
                    <div className="k-spacer">&nbsp;</div>
                    <Button
                      themeColor={"primary"}
                      onClick={async () => {
                        getFileForView();
                      }}
                    >
                      View
                    </Button>
                  </div>
                </>
              ) : (
                <></>
              )}
            </dd>
            <dd className="k-line-break"></dd>
          </dl>
        </div>
      </div>
    </>
  );
};

const MultipleSelectionRendering = (length: number) => {
  return (
    <div className="k-filemanager-preview" style={{ width: "100%", border: 0 }}>
      <div className="k-file-info">
        <span className="k-file-preview" style={{ width: "100%", border: 0 }}>
          <span className="k-file-icon k-icon k-i-file"></span>
        </span>
        <span className="k-file-name k-multiple-files-selected">
          {length} items
        </span>
      </div>
    </div>
  );
};
