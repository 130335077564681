import { useLayoutEffect, useState } from "react";

const useIsMobile = (width: number) => {
  const [isMobile, setIsMobile] = useState(false);

  //   useEffect(() => {
  //     console.log("exi");
  //     const mediaQuery = window.matchMedia("(max-width:500)");
  //     setIsMobile(mediaQuery.matches);

  //     const handleMediaQueryChange = (event) => {
  //       setIsMobile(event.matches);
  //     };
  //     mediaQuery.addEventListener("change", handleMediaQueryChange);

  //     return () => {
  //       mediaQuery.removeEventListener("change", handleMediaQueryChange);
  //     };
  //   }, [window.onresize]);

  const [windowSize, setWindowSize] = useState([0, 0]);
  const updateWindowSize = () => {
    setWindowSize([window.innerWidth, window.innerHeight]);
    if (window.innerWidth <= width) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };
  useLayoutEffect(() => {
    window.addEventListener("resize", updateWindowSize);
    updateWindowSize();
    return () => window.removeEventListener("resize", updateWindowSize);
  }, []);

  return isMobile;
};

export default useIsMobile;
