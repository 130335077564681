import React, { useEffect } from "react";
import { useAuth } from "react-oidc-context";

type Props = {};

const Autologin = (props: Props) => {
  const auth = useAuth();
  useEffect(() => {
    auth.signinRedirect();
  }, []);
  return <div>autologin</div>;
};

export default Autologin;
