import { Avatar, AppBar } from "@progress/kendo-react-layout";
import "./NavBar.css";
import { NavLink } from "react-router-dom";
import { useEffect, useState } from "react";
import { useAuth } from "react-oidc-context";

export default function NavBar() {
  const [clicked, setClicked] = useState<boolean>(false);
  const [username, setUserName] = useState<string | null | undefined>("");
  const handleclick = () => {
    setClicked(!clicked);
  };
  const auth = useAuth();

  useEffect(() => {
    if (auth.user) {
      setUserName(auth.user.profile.email);
    } else {
      console.log("user not found");
    }
  }, [auth]);
  return (
    <AppBar className="navContainer">
      <div className="navItems">
        <div id="logo">
          <NavLink to="/home" className="navLink" id="logotext">
            S3 Browser
          </NavLink>
        </div>
      </div>

      <div className="navLinks">
        <ul id="navbar" className={clicked ? "#navbar active" : "#navbar"}>
          <li className="navigateOptions">
            <NavLink to="/home" className="navLink">
              Home
            </NavLink>
          </li>
          <li className="navigateOptions">
            <NavLink to="/adminstration" className="navLink">
              Administration
            </NavLink>
          </li>
          <li className="avatar">
            <Avatar type="icon" size="medium" themeColor="primary">
              {username ? (
                <span className="profileAvatar">
                  {username.charAt(0).toUpperCase()}
                </span>
              ) : (
                <span className="k-icon k-i-user" />
              )}
            </Avatar>
          </li>
        </ul>
      </div>
      <div
        className="smallscreen"
        onClick={() => {
          handleclick();
        }}
      >
        <span
          id="bar"
          className={clicked ? "k-icon k-i-logout" : "k-icon k-i-sort-desc"}
        ></span>
      </div>
    </AppBar>
  );
}
