import React, { useEffect, useState } from 'react';
import { DropDownList } from "@progress/kendo-react-dropdowns";
import * as XLSX from 'xlsx';
import './viewer.css';

function fileToArrayBuffer(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = (event) => {
      resolve(event.target.result);
    };

    reader.onerror = (event) => {
      reject(new Error('Failed to read file as ArrayBuffer'));
    };

    reader.readAsArrayBuffer(file);
  });
}

function XLViewer(props) {
  const [excelData, setExcelData] = useState([]);
  const [sheets, setSheets] = useState([]);
  const [value, setValue] = useState({ "text": "sheet 1", "id": 1 });
  const [excelSheetNumber, setExcelSheetNumber] = useState(0);
  const [visibleRows, setVisibleRows] = useState(1000);
  const [loading, setLoading] = useState(false);

  const fetchData = async () => {
    try {
      setLoading(true);
      if (props.excelFile !== null) {
        const arrayBuffer = await fileToArrayBuffer(props.excelFile);
        const workbook = XLSX.read(arrayBuffer, { type: 'buffer', cellText: false, cellDates: true });
        const worksheetName = workbook.SheetNames[excelSheetNumber];
        const worksheet = workbook.Sheets[worksheetName];
        const data = XLSX.utils.sheet_to_json(worksheet, { raw: false, dateNF: 'mm/dd/yyyy', blankrows: true, defval: '' });

        let sheetNames = workbook.SheetNames.map((sheet, index) => {
          return { "text": sheet, "id": index + 1 };
        });

        setSheets(sheetNames);
        setValue(sheetNames[excelSheetNumber]);
        setExcelData(data);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [excelSheetNumber, props.excelFile]);

  const handleChange = (event) => {
    setExcelSheetNumber(event.target.value.id - 1);
  };

  const loadMoreRows = () => {
    setVisibleRows(prevVisibleRows => prevVisibleRows + 100); // Load additional 100 rows
  };

  const visibleData = excelData.slice(0, visibleRows);

  return (
    <>
      <div className='xlHeader'>
        <div><h1>{props.fileName}</h1></div>
        <div>
          <DropDownList
            data={sheets}
            textField="text"
            dataItemKey="id"
            defaultValue={value}
            value={value}
            onChange={handleChange}
            style={{
              width: "300px",
            }}
          />
        </div>
      </div>
      <div className="table-responsive">
        {loading ? <p>Loading the content...</p> : (
          visibleData && visibleData.length > 0 ? (
            <>
              <table className="exceltable">
                <thead>
                  <tr>
                    {Object.keys(visibleData[0]).map((key) => (
                      <th key={key}>{key}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {visibleData.map((individualExcelData, index) => (
                    <tr key={index}>
                      {Object.keys(individualExcelData).map((key) => (
                        <td key={key}>{individualExcelData[key]}</td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
              {visibleRows < excelData.length && (
                <button className='loadMore' onClick={loadMoreRows}>Load More</button>
              )}
            </>
          ) : <p>No data available.</p>
        )}
      </div>
    </>
  );
}

export default XLViewer;
