import { useState, useEffect, useCallback } from "react";

import Dropdown from "../Dropdown/Dropdown";
import { DropDownListChangeEvent } from "@progress/kendo-react-dropdowns";
import { DropdownData } from "../Dropdown/Dropdown";

import "./Selector.css";
import {
  getAccounts,
  getBucketsByAccountId,getAllAccountsAndBuckets
} from "../../Services/AcountService";
import { Account, Bucket,AccountAndBucketData,Group } from "../../models/types";
import { useSelector, useDispatch } from 'react-redux';
import { setBucketDetail ,RootState} from "../../Services/Store"


type Props = {
  setSelectedBucketinParent: (bucketName: string) => void;
  buttonLabel: string;
};


function Selector(props: Props) {

  const dispatch = useDispatch();

  const [accountDetails, setAccountDetails] = useState<AccountAndBucketData[]>([]);
  const [bucketDetails, setBucketDetails] = useState<Bucket[]>([]);
  const [selectedBucket, setSelectedBucket] = useState("");
  const [isAccountsFetching, setAccountsFetching] = useState(false);
  const [isBucketsFetching, setBucketsFetching] = useState(false);
  const [isDisplay, setDisplay] = useState(false);
  const [selectedAccountId, setSelectedAccountId] = useState("");
  const [selectedAccountName, setSelectedAccountName] = useState("");
  const profile = useSelector((state:RootState) => state.group);


  const [selectedBucketDetails, setSelectedBucketDetails] = useState<Bucket | null>(null);

  const GetAllAccountDetails = useCallback(async () => {
    try {
        setAccountsFetching(true);
        
        // Checks if profile.profiles is empty, if so, stop loading and return
        if (profile.profiles.length === 0) {
            setAccountsFetching(false);
            return;
        }
        
        const acc = await getAllAccountsAndBuckets();

        if (typeof acc !== "string") {
        //the bucket rendered from the redux store 
            const bucketNames = profile.profiles.map((item) => item.bucketName);

            const filteredAccounts = acc.filter((account) =>
                account.buckets.some((bucket) => bucketNames.includes(bucket.bucketName))
            );

            setAccountDetails(
                filteredAccounts.map((item, key) => ({
                    id: key,
                    accountId: item.accountId,
                    accountName: item.accountName,
                    buckets: item.buckets
                }))
            );
            setAccountsFetching(false);
        }
    } catch (error) {
        console.log("Error:", error);
    }
}, [profile]);

  
  const onAccountChange = (event: DropDownListChangeEvent) => {
    const value = event.target.value;
    
    const account = accountDetails.find((item) => item.accountName === value);
    if (account !== undefined) {
      setSelectedAccountId(account.accountId);
      setSelectedAccountName(account.accountName);
      setBucketsFetching(true);
      setSelectedBucket("");
    
      const filteredBucketDetails = account.buckets
      .filter((bucket) =>
        profile.profiles.some((item) => item.bucketName === bucket.bucketName)
      )
      .map((bucket) => {
        const matchedProfiles = profile.profiles.filter(
          (item) => item.bucketName === bucket.bucketName
        );
        let matchedProfile;
        if (matchedProfiles.length === 1) {
          matchedProfile = matchedProfiles[0];
        } else if (matchedProfiles.length > 1) {
          matchedProfile = matchedProfiles.find((item) => item.access === "w");
          if (!matchedProfile) {
            matchedProfile = matchedProfiles[0];
          }
        }
        return matchedProfile
          ? {
              ...bucket,
              folders: matchedProfile.folder,
              access: matchedProfile.access,
            }
          : bucket;
      });
      setBucketDetails(filteredBucketDetails);
    
   
      setBucketsFetching(false);
    }
  };

  
  
  
  

  const onBucketChange = (event: DropDownListChangeEvent) => {
    const selectedBucketName = event.target.value;
  const bucket = bucketDetails.find((item) => item.bucketName === selectedBucketName);
  if (bucket) {
    setSelectedBucket(bucket.bucketName);
    setSelectedBucketDetails(bucket);
    dispatch(setBucketDetail(bucket));
    setDisplay(true);
  } else {
    setSelectedBucket('');
    setSelectedBucketDetails(null);
    setDisplay(false);
  }
  };

  useEffect(() => {
    if (accountDetails?.length === 0) {
      GetAllAccountDetails();
    }
  }, [accountDetails]);

  useEffect(() => {
    if (selectedAccountId !== undefined && selectedAccountId !== "") {
      GetAllAccountDetails();
    }
  }, [selectedAccountId]);




  const awsAccount: DropdownData = {
    options: accountDetails.map((i) => i.accountName) ?? [],
    label: "AWS Account",
    defaultValue: "Select an account",
    id: "AccountDetails",
  };

  const buckets: DropdownData = {
    options: bucketDetails.map((i) => i.bucketName),
    label: "Buckets",
    defaultValue: "Select a bucket",
    id: "AccountBucketDetails",
  };

  const clearSelectedAccount = () => {
    setSelectedAccountId("");
  };
  const clearSelectedBucket = () => {
    setSelectedBucket("");
  };
  return (
    <div className="selectors">
      <Dropdown
        data={awsAccount}
        onChange={onAccountChange}
        isLoading={isAccountsFetching}
        currentValue={selectedAccountName}
        clearCurrentValue={clearSelectedAccount}
      />

      <Dropdown
        data={buckets}
        onChange={onBucketChange}
        isLoading={isBucketsFetching}
        currentValue={selectedBucket}
        clearCurrentValue={clearSelectedBucket}
      />

      <button
        type={"submit"}
        disabled={!isDisplay}
        className=" searchButton k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary "
        onClick={() => {
          props.setSelectedBucketinParent(selectedBucket);
        }}
      >
        {props.buttonLabel}
      </button>
    
    </div>
  );
}

export default Selector;
/*  const loadBuckets = useCallback(async () => {
    setBucketsFetching(true);
    if (selectedAccountId !== undefined && selectedAccountId !== "") {
      const account = accountDetails.find((item) => item.accountId === selectedAccountId);
      console.log(account)
      if (account) {
        const buckets = account.buckets;
        setBucketDetails(buckets);
        setBucketsFetching(false);
      }
    }
  }, [selectedAccountId, accountDetails]);*/
