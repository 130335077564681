import { memo, useEffect, useRef } from "react";
import {
  Splitter,
  SplitterOnChangeEvent,
  BreadcrumbLinkMouseEvent,
} from "@progress/kendo-react-layout";
import { useInternationalization } from "@progress/kendo-react-intl";
import { process, SortDescriptor, State } from "@progress/kendo-data-query";
import { clone, getter } from "@progress/kendo-react-common";
import {
  GridRowDoubleClickEvent,
  GridRowClickEvent,
  getSelectedState,
} from "@progress/kendo-react-grid";
import { Button } from "@progress/kendo-react-buttons";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { Upload, UploadFileInfo } from "@progress/kendo-react-upload";

import { TreeViewItemClickEvent } from "@progress/kendo-react-treeview";
import { InputChangeEvent } from "@progress/kendo-react-inputs";
import { Offset } from "@progress/kendo-react-popup";

import { FileManagerToolbar } from "../ExplorerUtils/FileManagerToolbar";
import { GridView } from "../ExplorerUtils/GridView";
import { ListView } from "../ExplorerUtils/ListView";

import { FolderTree } from "../ExplorerUtils/FolderTree";
import { BreadcrumbComponent } from "../ExplorerUtils/Breadcrumb";
import { DeleteDialog, EditDialog } from "../ExplorerUtils/Dialog";

import {
  DataModel,
  PanesModel,
  SplitBtnItems,
  SelectedItemType,
  UploadAddEvent,
  ViewChangeEvent,
  SortChangeEvent,
  AppSwitchChangeEvent,
  ContextMenuEvent,
} from "../../models/FileManagerModels";
import {
  formatData,
  getFolderTree,
  searchTreeItem,
  addDataItem,
  addFolder,
  convertExtensionToIcon,
  convertToBreadcrumbData,
  getFolderPath,
} from "../../helpers/helperMethods";
import { ContextMenu } from "../ExplorerUtils/ContextMenu";
import { FileInformation } from "../ExplorerUtils/FileInformation";
import { useCallback, useState } from "react";
import useIsMobile from "../../hooks/useMobile";
import { MOBILE_WIDTH } from "../../constants";
import { savingFile } from "../../Services/FileService";

type Props = {
  stateData: DataModel[];
  bucketName: string;
  isUploadOnly: boolean;
  onFileUploadSuccess?: (uploadedFileInfo: UploadFileInfo) => void;
};

const splitterPanes: PanesModel[] = [
  {
    size: "20%",
    min: "20px",
    collapsible: true,
  },
  {},
  {
    size: "30%",
    min: "20px",
    collapsible: true,
  },
];

const initialSort: SortDescriptor[] = [
  {
    field: "path",
    dir: "asc",
  },
];
const DATA_ITEM_KEY = "path";

const SELECTED_FIELD = "selected";

const idGetter = getter(DATA_ITEM_KEY);
export const Explorer = (props: Props) => {
  const intl = useInternationalization();

  const [stateData, setStateData] = useState<DataModel[]>(
    formatData(props.stateData, intl)
  );
  const isMobile = useIsMobile(MOBILE_WIDTH);

  // useEffect(() => {
  //   formatData(props.stateData, intl);
  // }, [stateData]);

  const [panes, setPanes] = useState<PanesModel[]>(splitterPanes);
  const [selectedGridItem, setSelectedGridItem] = useState<DataModel>({});
  const [selectedTreeItem, setSelectedTreeItem] = useState<DataModel | null>(
    null
  );
  const [girdSelection, setGridSelection] = useState<{
    [id: string]: boolean | number[];
  }>({});
  const [detialsData, setDetailsData] = useState<null | number | Object>(null);
  const [files, setFiles] = useState<UploadFileInfo[]>([]);
  const [contentView, setContentView] = useState<string>("grid");
  const [contextMenuView, setContextMenuView] = useState<boolean>(false);
  const [menuOffSet, setMenuOffSet] = useState<Offset>({
    left: 0,
    top: 0,
  });

  const [editDialogView, setEditDialogView] = useState<boolean>(false);
  const [deleteDialogView, setDeleteDialogView] = useState<boolean>(false);
  const splitBtnItems: SplitBtnItems[] = [
    { text: "Name", value: "path" },
    { text: "File Size", value: "size" },
   // { text: "Date Created", value: "dateCreated" },
    { text: "Last Modified", value: "dateModified" },
  ];

  const initialLogic: "and" | "or" = "and";
  const [stateContentData, setStateContentData] = useState<State>({
    sort: initialSort,
    filter: {
      logic: initialLogic,
      filters: [{ field: "path", operator: "contains", value: "" }],
    },
  });

  const updateFileDetailsData = useCallback(
    (selection: SelectedItemType) => {
      if (selection.path) {
        const newSelectedItem = searchTreeItem(stateData, selection);
        setDetailsData(newSelectedItem);
      } else if (!selection.path && Object.keys(selection).length) {
        setDetailsData(Object.keys(selection).length);
      } else {
        setDetailsData(null);
      }
    },
    [stateData]
  );

  useEffect(() => {
    if (isMobile) {
      setContentView("list");
    } else {
      setContentView("grid");
    }
  }, [isMobile]);
  const handleTreeItemClick = (event: TreeViewItemClickEvent) => {
    const newSelectedItem: DataModel = searchTreeItem(stateData, event.item);

    newSelectedItem.expanded = !event.item.expanded;

    setSelectedTreeItem(newSelectedItem);
    setDetailsData(event.item);
    setSelectedGridItem({});
    setGridSelection({});
  };

  const handleSplitterChange = (event: SplitterOnChangeEvent) => {
    setPanes(event.newState);
  };
  const handleSelectionChange = (event: GridRowClickEvent) => {
    setSelectedGridItem(event.dataItem);
    updateFileDetailsData(event.dataItem);
    if (event.dataItem.path) {
      const key = event.dataItem["path"].toString();
      setGridSelection({ [key]: true });
    }
  };
  const handleMultipleSelection = (event) => {
    const newSelection = getSelectedState({
      event,
      selectedState: girdSelection,
      dataItemKey: DATA_ITEM_KEY,
    });

    setGridSelection(newSelection);
    updateFileDetailsData(newSelection);
  };

  const handleSortChange = (event: SortChangeEvent) => {
    const newSortedData = clone(stateContentData);

    if (event.direction === "asc" || event.direction === "desc") {
      newSortedData.sort[0].dir = event.direction;
    }

    if (event.item) {
      newSortedData.sort[0].field = event.item.value;
    }

    if (event.sort) {
      newSortedData.sort = event.sort;
    }
    setStateContentData(newSortedData);
  };
  const handleGridDoubleClick = (event: GridRowDoubleClickEvent) => {
    const itemType = convertExtensionToIcon(event.dataItem.path);
    if (itemType?.type === "Folder") {
      if (selectedTreeItem) {
        selectedTreeItem.expanded = true;
      }

      const newSelectedItem = event.dataItem;
      newSelectedItem.expanded = true;
      setSelectedTreeItem(searchTreeItem(stateData, newSelectedItem));
    }
  };
  const handleNewFolderClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    let newData;
    if (selectedTreeItem) {
      newData = addFolder(stateData, selectedTreeItem, intl);
      const newTreeItem = searchTreeItem(newData, selectedTreeItem);
      setSelectedTreeItem(newTreeItem);
    } else {
      newData = addFolder(stateData, null, intl);
    }
    setStateData(newData);
  };
  const handleSearchChange = (event: InputChangeEvent) => {
    let eventPath = "";
    if (selectedTreeItem?.items) {
      const curPath = getFolderPath(selectedTreeItem.items[0].path);
      eventPath = `${curPath}/${event.value}`;
    } else {
      const curPath = getFolderPath(stateData[0].path);
      eventPath = `${curPath}/${event.value}`;
    }

    setStateContentData({
      ...stateContentData,
      filter: {
        logic: initialLogic,
        filters: [{ field: "path", operator: "contains", value: eventPath }],
      },
    });
  };

  const handleSwitchChange = (event: AppSwitchChangeEvent) => {
    const newPanes: PanesModel[] = panes.slice(0);
    if (event.value) {
      newPanes[2].size = "30%";
      setPanes(newPanes);
    } else {
      newPanes[2].size = "0%";
      setPanes(newPanes);
    }
  };
  const handleViewChange = (event: ViewChangeEvent) => {
    if (event.viewValue.gridView) {
      setContentView("grid");
    }
    if (event.viewValue.listView) {
      setContentView("list");
    }
  };
  const handleFileChange = (event: UploadAddEvent) => {
    if (event.files) {
      setFiles(event.files);
    }
  };
  const handleClearFileList = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    if (event) {
      setFiles([]);
    }
  };

  const onFileUploadSuccess = (uploadedFileInfo) => {
    // Call the callback function passed from Home.tsx
    if (props.onFileUploadSuccess) {
      props.onFileUploadSuccess(uploadedFileInfo);
    }
  };

  const handleUploadComplete = async (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    let filename = files[0].name;

    let fileKey =
      selectedTreeItem === null
        ? null
        : selectedTreeItem.fileKey === undefined
        ? null
        : selectedTreeItem.fileKey;

    const cfile = files[0]?.getRawFile?.();
    if (cfile) await savingFile(fileKey, filename, props.bucketName, cfile);


    const newData: DataModel | any = addDataItem(
      stateData,
      selectedTreeItem,
      files,
      intl
    );

    setStateData(newData);
    setFiles([]);
    onFileUploadSuccess(files[0]);
  };
  const handleBreadcrumbSelection = (event: BreadcrumbLinkMouseEvent) => {
    setSelectedGridItem({});
    setGridSelection({});

    if (!event.nativeEvent.keyCode) {
      const newItem = searchTreeItem(stateData, { path: event.id });
      setSelectedTreeItem(newItem);
    }

    if (event.nativeEvent.keyCode === 13) {
      const newItem = searchTreeItem(stateData, { path: event.id });
      setSelectedTreeItem(newItem);
    }
  };
  const handleDialogClick = (event) => {
    // if (event.type === "cancel") {
    //   setDeleteDialogView(false);
    //   setEditDialogView(false);
    // }
    // const curItem: DataModel | null = Object.keys(selectedGridItem).length
    //   ? selectedGridItem
    //   : selectedTreeItem;
    // if (!curItem) {
    //   return;
    // }
    // if (event.type === "delete") {
    //   const newItem: string = curItem.path || "";
    //   const oldPath = newItem.split("/");
    //   oldPath.pop();
    //   const updatedData = removeDataItem(stateData, curItem);
    //   let newItemPath = oldPath.join("/");
    //   setSelectedTreeItem(searchTreeItem(updatedData, { path: newItemPath }));
    //   setStateData(updatedData);
    //   setDeleteDialogView(false);
    // }
    // if (event.type === "rename") {
    //   const folderPath = getFolderPath(event.path);
    //   const newPath = `${folderPath}/${event.value}`;
    //   const existingItem = searchTreeItem(stateData, { path: newPath });
    //   if (!existingItem) {
    //     const updatedData = editDataItem(stateData, curItem, newPath);
    //     setSelectedTreeItem(searchTreeItem(updatedData, { path: newPath }));
    //     setStateData(updatedData);
    //   }
    //   setEditDialogView(false);
    // }
  };

  const handleListItemClick = (event) => {
    // console.log(event.dataItem);
    let newSelectedGridItem = {};
    if (event.dataItem.path) {
      newSelectedGridItem = event.dataItem;
      newSelectedGridItem[event.dataItem.path] = true;
      newSelectedGridItem["selected"] = true;

      setSelectedGridItem(newSelectedGridItem);
      if (event.dataItem.path) {
        const key = event.dataItem.path.toString();
        setGridSelection({ [key]: true });
      }
    }

    if (event.tree) {
      const newSelectedTreeItem: DataModel = searchTreeItem(
        stateData,
        newSelectedGridItem
      );
      setSelectedTreeItem(newSelectedTreeItem);
    }
    updateFileDetailsData(newSelectedGridItem);
  };
  const handleContextMenuView = (event: ContextMenuEvent) => {
    setContextMenuView(true);
    setMenuOffSet({ left: event.event.clientX, top: event.event.clientY });
    handleListItemClick(event);
  };

  const handleContextMenuClick = (event: ContextMenuEvent) => {
    if (event.itemId === "0") {
      setEditDialogView(false);
    } else {
      setDeleteDialogView(true);
    }
  };
  const handleDialogClose = () => {
    setDeleteDialogView(false);
    setEditDialogView(false);
  };

  document.addEventListener("click", () => {
    setContextMenuView(false);
  });

  const [dialogVisible, setDialogVisible] = useState<boolean>(false);

  const handleDialogVisibility = () => {
    setDialogVisible(!dialogVisible);
  };
  const requestIntervalRef = useRef({});
  const progressRef = useRef({});

  const onSaveRequest = (
    files: UploadFileInfo[],
    options: { formData: FormData; requestOptions: any },
    onProgress: (uid: string, event: ProgressEvent<EventTarget>) => void
  ): Promise<{ uid: string }> => {
    const uid = files[0].uid;
    progressRef.current[uid] = 0;
    const saveRequestPromise: Promise<{ uid: string }> = new Promise(
      (resolve, reject) => {
        requestIntervalRef.current[uid] = setInterval(() => {
          resolve({ uid: uid });
          // onProgress(uid, ()=> { loaded: progressRef.current[uid], total: 100 });
          // if (progressRef.current[uid] === 100) {
          //   resolve({ uid: uid });

          //   delete progressRef.current[uid];
          //   clearInterval(requestIntervalRef.current[uid]);
          // } else {
          //   reject({ uid: uid });
          //   progressRef.current[uid] = progressRef.current[uid] + 1;
          // }
        }, 40);
      }
    );
    return saveRequestPromise;
  };

  const handleUploadDone = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    setDialogVisible(!dialogVisible);
    handleUploadComplete(event);
  };
  return (
    <>
      {props.isUploadOnly === false ? (
        <div className="k-widget k-filemanager k-filemanager-resizable fileExplorer">
          <div className="k-filemanager-header">
            <FileManagerToolbar
              splitItems={splitBtnItems}
              sort={stateContentData.sort}
              files={files}
              onNewFolderClick={handleNewFolderClick}
              onSearchChange={handleSearchChange}
              onSwitchChange={handleSwitchChange}
              onViewChange={handleViewChange}
              onSortChange={handleSortChange}
              onFileChange={handleFileChange}
              onClearFileList={handleClearFileList}
              onUploadComplete={handleUploadComplete}
            />
          </div>
          <div className="k-filemanager-content-container">
            <Splitter panes={panes} onChange={handleSplitterChange}>
              <FolderTree
                data={getFolderTree(stateData, selectedTreeItem)}
                selected={selectedTreeItem}
                selectedItem={selectedTreeItem}
                onItemClick={handleTreeItemClick}
                onContextMenu={handleContextMenuView}
              />
              <div className="k-filemanager-content">
                <BreadcrumbComponent
                  data={convertToBreadcrumbData(selectedTreeItem)}
                  onBreadcrumbSelect={handleBreadcrumbSelection}
                />
                {contentView === "grid" ? (
                  <GridView
                    data={
                      selectedTreeItem && selectedTreeItem.items
                        ? process(
                            selectedTreeItem.items.map((item) => ({
                              ...item,
                              [SELECTED_FIELD]: girdSelection[idGetter(item)],
                            })),
                            stateContentData
                          )
                        : !selectedTreeItem
                        ? process(
                            stateData.map((item) => ({
                              ...item,
                              [SELECTED_FIELD]: girdSelection[idGetter(item)],
                            })),
                            stateContentData
                          )
                        : null
                    }
                    sort={stateContentData.sort}
                    dataItemKey={DATA_ITEM_KEY}
                    onSelectionChange={handleSelectionChange}
                    onMultipleSelection={handleMultipleSelection}
                    onSortChange={handleSortChange}
                    onDoubleClick={handleGridDoubleClick}
                    onContextMenu={handleContextMenuView}
                  />
                ) : (
                  <ListView
                    data={
                      selectedTreeItem && selectedTreeItem.items
                        ? process(
                            selectedTreeItem.items.map((item) => ({
                              ...item,
                              [SELECTED_FIELD]: girdSelection[idGetter(item)],
                            })),
                            stateContentData
                          )
                        : !selectedTreeItem
                        ? process(
                            stateData.map((item) => ({
                              ...item,
                              [SELECTED_FIELD]: girdSelection[idGetter(item)],
                            })),
                            stateContentData
                          )
                        : null
                    }
                    onSelectionChange={handleSelectionChange}
                    onMultipleSelection={handleMultipleSelection}
                    onItemClick={handleListItemClick}
                    onDoubleClick={handleGridDoubleClick}
                    onContextMenu={handleContextMenuView}
                  />
                )}
                {contextMenuView ? (
                  <ContextMenu
                    offset={menuOffSet}
                    onContextMenuCLick={handleContextMenuClick}
                  />
                ) : (
                  ""
                )}
                {editDialogView ? (
                  <EditDialog
                    editValue={
                      Object.keys(selectedGridItem).length
                        ? selectedGridItem
                        : selectedTreeItem
                    }
                    onDialogClose={handleDialogClose}
                    onDialogClick={handleDialogClick}
                  />
                ) : (
                  ""
                )}
                {deleteDialogView ? (
                  <DeleteDialog
                    onDialogClose={handleDialogClose}
                    onDialogClick={handleDialogClick}
                  />
                ) : (
                  ""
                )}
              </div>
              {!isMobile && <FileInformation data={detialsData} />}
            </Splitter>
          </div>
        </div>
      ) : (
        <>
          <div className="placeholderLabel">
            No Files/Folder found for this bucket,{" "}
            <span
              color="primary"
              className="uploadFileLink"
              onClick={handleDialogVisibility}
            >
              {" "}
              Start Uploading files
            </span>
          </div>
          {/* <Button themeColor={"primary"} onClick={handleDialogVisibility}>
            Start Uploading files
          </Button> */}
          {dialogVisible && (
            <Dialog
              title={"Upload Files"}
              className={"k-filemanager-upload-dialog"}
              onClose={handleDialogVisibility}
              contentStyle={{ width: "530px" }}
            >
              <Upload
                batch={false}
                multiple={false}
                files={files}
                withCredentials={false}
                onAdd={handleFileChange}
                onRemove={handleFileChange}
                onProgress={handleFileChange}
                onStatusChange={handleFileChange}
                // save={onSaveRequest}
                //removeUrl={onRemoveRequest}
                saveUrl={onSaveRequest}
              />
              <DialogActionsBar layout={"end"}>
                <Button onClick={handleClearFileList}> Clear List</Button>
                <Button themeColor={"primary"} onClick={handleUploadDone}>
                  Upload
                </Button>
              </DialogActionsBar>
            </Dialog>
          )}
        </>
      )}
    </>
  );
};

export default memo(Explorer);
