import { Navigate, Routes, Route } from "react-router-dom";
import Home from "./Pages/Home/Home";
import axios, { AxiosRequestConfig } from "axios";
import { useAuth } from "react-oidc-context";
// import "./App.scss";
import Autologin from "./Pages/AutoLogin/autologin";

import Adminstration from "./Pages/Adminstration/Adminstration";
import { useEffect } from "react";

function App() {
  let auth = useAuth();
  let user = auth.user;

  if (user != undefined) {
   // console.log(user.access_token);
    localStorage.setItem("accesstoken", user.access_token);
  }

  axios.interceptors.request.use(
    (request: AxiosRequestConfig): AxiosRequestConfig => {
      if (request.headers !== undefined) {
        request.headers["authorizationToken"] =
          localStorage.getItem("accesstoken");

        request.headers["Content-Type"] = "application/json;charset=UTF-8";
        request.headers["Access-Control-Allow-Origin"] = "*";

        
      }
      request.timeout = 60000;
      return request;
    }
  );
 

  switch (auth.activeNavigator) {
    case "signinSilent":
      return <div>Signing you in...</div>;
    case "signoutRedirect":
      return <div>Signing you out...</div>;
  }

  if (auth.isLoading) {
    return (
      <div className="App">
        <div>Loading..</div>
      </div>
    );
  }

  if (auth.error) {
    console.log(auth.error)
 
      return (
        <>
      {/* <div>Error occured while authenticating...<b>{"`"+auth.error.message+"`"}</b></div> */}
        <div>Error occured while authenticating...<b>Kindly try refreshing the browser or open the tab again</b></div>
        <Navigate to={{ pathname: "/home" }} />
        </>
      )
    
  }
  

  if (auth.isAuthenticated) {
    // ValidateToken(auth.user?.access_token!);

    return (
      <div className="App">
        <Routes>
          <Route
            path="/"
            element={<Navigate to={{ pathname: "/home" }} replace />}
          />

          <Route path="/home" element={<Home />}></Route>
          <Route path="/home/:code" element={<Home />}></Route>
          <Route path="/auth/:code" element={<Home />}></Route>
          <Route
            path="/adminstration"
            element={<Adminstration></Adminstration>}
          ></Route>
        </Routes>
      </div>
    );
  }

 
  return <Autologin></Autologin>;
}

export default App;
