import { DropDownList } from "@progress/kendo-react-dropdowns";
import "./Dropdown.css";

export type DropdownData = {
  options: string[];
  label: string;
  defaultValue: string;
  id: string;
};

type DropdownProps = {
  isLoading: boolean;
  data: DropdownData;
  currentValue: string;
  onChange: (event: any, children?: React.ReactNode) => void;
  clearCurrentValue: () => void;
};

export default function Dropdown(props: DropdownProps) {
  const { data, onChange, isLoading, currentValue } = props;

  return (
    <div className="DropdownContainer">
      <div className="dropdownlabel">{data.label}</div>
      <DropDownList
        className="dropdown"
        fillMode="solid"
        style={{ width: "320px", height: "40px" }}
        data={data.options}
        onChange={onChange}
        ignoreCase={true}
        loading={isLoading}
        rounded="large"
        size={"large"}
        value={currentValue || data.defaultValue}
      />
    </div>
  );
}
