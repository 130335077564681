import React, { useEffect, useRef, useState } from 'react';
import mammoth from 'mammoth';


export default function DocTypeViewer({ docxFile }) {
  const [htmlContent, setHtmlContent] = useState<string | null>(null);
  const [fileName, setfileName] = useState<string | null>(null);
 

  useEffect(() => {
    const convertDocxToHtml = async () => {
      try {
        const result = await mammoth.convertToHtml({ arrayBuffer: docxFile });
        setHtmlContent(result.value);
        setfileName(docxFile.name)
      } catch (error) {
        console.error('Error converting DOCX to HTML:', error);
        // Handle the error, e.g., display an error message
      }
    };

    if (docxFile) {
      convertDocxToHtml();
    }
  }, [docxFile]);

  return (
    <div>
      {htmlContent ? (
        <>
        <h2>{fileName}</h2>
        <div dangerouslySetInnerHTML={{ __html: htmlContent }} />
        </>
      ) : (
        <p>Error in displaying DOCX files</p>
      )}
    </div>
  );
};

