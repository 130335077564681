import axios, { AxiosRequestConfig } from "axios";
//import { baseURL } from "../constants";
import { DataModel } from "../models/FileManagerModels";
import { Account, Bucket, AccountAndBucketData,FileStructure, Error } from "../models/types";

const baseURL =process.env.REACT_APP_API_BASEURL??"";
export async function getAccounts(): Promise<Account[] | Error> {
  try {
    const config = {
      withCredentials: false,
      timeout: 6000,
    };

    const res = await axios.get<Account[]>(baseURL + "GetAccountList", config);
  
    return new Promise((reso, _) => reso(res.data));
   
    //  return res.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.log("error message: ", error.message);
      return new Promise((_, rej) => rej("Axios Error occured"));
    } else {
      console.log("unexpected error: ", error);
      return new Promise((_, rej) => rej("An unexpected error occurred"));
      //      return "An unexpected error occurred";
    }
  }
}
export async function getAllAccountsAndBuckets(): Promise<AccountAndBucketData[] | Error> {
  try {
    const config = {
      withCredentials: false,
      timeout: 6000,
    };

    const res = await axios.get<AccountAndBucketData[]>(baseURL + "GetAllAccountsAndBuckets", config);
  
    return new Promise((reso, _) => reso(res.data));
   
    //  return res.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.log("error message: ", error.message);
      return new Promise((_, rej) => rej("Axios Error occured"));
    } else {
      console.log("unexpected error: ", error);
      return new Promise((_, rej) => rej("An unexpected error occurred"));
      //      return "An unexpected error occurred";
    }
  }
}



export async function getBucketsByAccountId(
  accountId: string
): Promise<Bucket[] | Error> {
  let ress: Bucket[];
  try {
    const res = await axios.get<Bucket[]>(
      baseURL + `GetBucketListByAccountId?AccountId=${accountId}`
    );
    ress = res.data;
   

  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.log("error message: ", error.message);
      return new Promise((_, rej) => rej("Axios Error occured"));
    } else {
      console.log("unexpected error: ", error);
      return new Promise((_, rej) => rej("An unexpected error occurred"));
      //      return "An unexpected error occurred";
    }
  }
  return new Promise((reso, _) => reso(ress));
}

export async function getBucketObjects(
  bucketName: string
): Promise<FileStructure[]> {
  let result: FileStructure[];
 
  try {
   
    const res = axios.get<FileStructure[]>(
      baseURL + `ListAllObjectsByBucket?bucketName=${bucketName}`,
      {
        headers: { Accept: "application/json" },
        withCredentials: false,
        timeout: 6000,
      }
    );
    result = (await res).data;
    console.log(result)

    return new Promise((res, _) => res(result));
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.log("error message: ", error.message);
      return new Promise((_, rej) => rej([]));
      //   return error.message;
    } else {
      console.log("unexpected error: ", error);
      return new Promise((_, rej) => rej([]));
      // return "An unexpected error occurred";
    }
  }
}

let refactor = (c: FileStructure[], bucketName: string): DataModel[] => {
  if (c.length === 0 || c === null) return [];

  return c.map((item): DataModel => {
    const lastDateModified = item.lastModified ? new Date(item.lastModified) : null;
    //console.log(lastDateModified)
    
    
    return {
      id: item.id,
      level: item.level,
      label: item.label,
      fileKey: item.key,
      path: `${bucketName}/${item.key}`,
      expanded: false,
      selected: false,
      size:item.size,
      dateCreated: new Date(),
      dateModified: lastDateModified,
      items: refactor(item.children ?? [], bucketName),
      edit: false,
      length: 0,
      storageClass: item.storageClass,
      bucketName: bucketName,
    };
   
  });

};

export async function getFiles(bucketName: string): Promise<DataModel[]> {
  console.log(baseURL);
  let result: DataModel[];
  try {
    const res = axios.get<FileStructure[]>(
      baseURL + `ListAllObjectsByBucket?bucketName=${bucketName}`,
      {
        headers: { Accept: "application/json" },
        withCredentials: false,
        timeout: 6000,
      }
    );

    var r = (await res).data;
  

    result = refactor(r, bucketName);
    //console.log(result)

    return new Promise((res, _) => res(result));
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.log("error message: ", error.message);
      return new Promise((_, rej) => rej([]));
      //   return error.message;
    } else {
      console.log("unexpected error: ", error);
      return new Promise((_, rej) => rej([]));
      // return "An unexpected error occurred";
    }
  }
}

export async function fileUploadPreSignedURL(
  bucketname: string,
  fileName: string,
  key: string | null
): Promise<string> {
  return new Promise(async (resolve, reject) => {
    try {
      let k = key === null ? fileName : `${key}/${fileName}`;
      const options: AxiosRequestConfig = {
        method: "GET",
        url: `${baseURL}GetUploadUrl`,
        params: {
          bucketName: bucketname,
          key: k,
        },
      };

      let result = await axios.request(options);

      resolve(result.data.url);
    } catch (e) {
      reject("null");
    }
  });
}

export async function uploadFileToBucket(
  preSignedURL: string,
  file: File
): Promise<boolean> {
  const config: AxiosRequestConfig = {
    method: "PUT",
    url: preSignedURL,
    headers: {
      "Content-Type": "application/octet-stream",
    },
    withCredentials: true,
    data: file,
  };
  try {
    await axios(config);

    return true;
  } catch (e) {
    return false;
  }
}

export async function uploadFileFetch(preSignedURL: string, file: File) {
  try {
    fetch(preSignedURL, {
      method: "PUT",
      body: file,
      headers: {
        "Content-Type": "application/octet-stream",
        // "Access-Control-Allow-Origin": "*",
      },
    })
      .then((res) => res.json())
      .then((result) => {
        return true;
      })
      .catch((e) => {
        return false;
      });
  } catch (e) {
    return false;
  }
}
